import React from "react";

class Snippets extends React.Component {
    render () {
        return (
            <section id="portfolio" className="site-section">
                <h1 className="title">Examples</h1>
                <ul id="portfolio-wrap">
                    
                    <li className="portfolio-item">
                        <a href="//www.jaredhague.com/space" target="_blank" className="img-link-wrap">
                            <img src="./img/space.png" alt="Screenshot of People in Space" />
                            
                            <p className="portfolio-description">
                                <h2 className="portfolio-title">People in Space</h2>
                                <a href="//www.jaredhague.com/space" target="_blank" className="btn-wrap"><span className="link-btn">View Site</span></a>
                            </p>
                        </a>
                        
                    </li>
                    
                </ul>

          </section>
        )
    }
}

export default Snippets;