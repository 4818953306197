import React from "react";

class Portfolio extends React.Component {
    render () {
        return (
            <section id="portfolio" className="site-section">
                <h1 className="title">Portfolio</h1>
                <ul id="portfolio-wrap">
                    <li className="portfolio-item">      
                        <a href="//www.wildcatecho.com" target="_blank" className="img-link-wrap">
                            <img src="../img/we.png" alt="Screenshot of Wildcat Echo home page" />
                            <div className="portfolio-description">
                                <h2 className="portfolio-title">Wildcat Echo</h2>
                                <p>Skills Used: WordPress, Web Design, JavaScript, Deployment</p>
                                <a href="//www.wildcatecho.com/" target="_blank" className="btn-wrap"><span className="link-btn">View Site</span></a>
                            </div>
                        </a>
                    </li>
                    
                    <li className="portfolio-item">
                        
                        <a href="//www.susielinquist.com" target="_blank" className="img-link-wrap">
                            <img src="./img/susie.png" alt="Screenshot of People in Space" />
                            <div className="portfolio-description">
                                <h2 className="portfolio-title">Susie Linquist</h2>
                                <p>Skills Used: WordPress Custom Theme, Web Design, JavaScript, Deployment, Hosting</p>
                                <a href="//susielinquist.com/" target="_blank" className="btn-wrap"><span className="link-btn">View Site</span></a>
                            
                            </div>
                        </a>
                        
                        
                    </li>
                </ul>

          </section>
        )
    }
}

export default Portfolio;