import React, { Component } from 'react';
import './App.scss';
import FrontpagePosts from './FrontpagePosts';
import Background from './Background';
import Portfolio from './Portfolio';
import Snippets from './Snippets';


class App extends Component {
  constructor (){
    super();
  
    this.state = {
      posts: []
    }
  }

  componentDidMount() {
    fetch(`//localhost/jaredhague/wp-json/wp/v2/posts/`)
      .then((response) => response.json())
      .then((response) => {
       
 
        this.setState({ 
          posts: response
        })
    })
    .catch((error) => {
      console.error(error);
    });        
  }

  render() {
    return (
      
      //Begin Website
      <div className="App" particles-js>
    
        <header className="App-header">
          <a href="#home">
            <div id="header-name">
              <span className="header-name-letter" id="name-j">J</span>
              <span className="header-name-letter" id="name-a">A</span>
              <span className="header-name-letter" id="name-r">R</span>
              <span className="header-name-letter" id="name-e">E</span>
              <span className="header-name-letter" id="name-d">D</span>
              <span className="header-name-letter" id="name-h">H</span>
              <span className="header-name-letter" id="name-a-l">A</span>
              <span className="header-name-letter" id="name-g">G</span>
              <span className="header-name-letter" id="name-u">U</span>
              <span className="header-name-letter" id="name-e-l">E</span>
            </div>
          </a>
        </header>
        
        <div className="content-wrap" id="home">
          
          <section id="about">
            <div id="background-effect">
              <Background />
            </div>
            
            <div id="bio">
              <div id="intro">
                
                <h1>
                  <span>Hi! </span>
                  <span>I'm Jared.</span>
                </h1>
                <img id="headshot" src="./img/headshot-small.png" alt="Jared Hague Headshot"/>
              </div>
              
              <p>I'm a digital marketing specialist and front-end developer based in Atlanta, Ga. I have expertise in building custom WordPress themes, creating custom landing pages, managing e-mail marketing campaigns, implementing marketing automation, and working with HTML, CSS, and JavaScript.</p>
              
              <p>I am currently seeking new opportunities as a digital marketing specialist or front-end developer. While I am looking for a postion, I have been keeping busy learning React and working on my AWS Associate Developer certification. </p>

              <p>Get In Touch:</p>
              <ul id="contact-list">
                
                <li className="list-item">
                    <a className="link" href="https://www.linkedin.com/in/jaredahague/" target="_blank">
                      <span className="list-item-name">LinkedIn</span>
                    </a>
                </li>
                
                <li className="list-item">
                    <a className="link" href="mailto:jared@jaredhague.com" target="_blank">             <span className="list-item-name">Email</span>
                    </a>
                </li>
                <li className="list-item">
                    <a className="link" href="https://github.com/jaredhague" target="_blank">            <span className="list-item-name">GitHub</span>
                    </a>
                </li>
                
              </ul>
            </div>
            
          </section>

          <Portfolio />

          <Snippets />

          <section id="blog-frontpage">
          <h1>Recent Posts</h1>
            <div className="posts">
              <p>Blog posts are coming soon.</p>
             {/*<FrontpagePosts posts={this.state.posts} /> */}
            </div>
          <div>
          
          </div>
        
          </section>

        </div>
        
      </div>
    );
  }
}

export default App;
